export const SET_LOADER = 'SET_LOADER';
export const SET_ACCOUNT_CHECKS = 'SET_ACCOUNT_CHECKS';

export const setSpinnerAction = (payload) => ({
  type: SET_LOADER,
  payload,
});

export const setAccountChecksAction = (payload) => ({
  type: SET_ACCOUNT_CHECKS,
  payload,
});
