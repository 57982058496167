import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import loaderReducer from './loaderReducer';
import generalChecksReducer from './generalChecksReducer';
import studentReducer from './studentReducer';
import userReducer from './userReducer';
import subjectsReducer from './subjectsReducer';
import tutorReducer from './tutorReducer';
import commonReducer from './commonReducer';

const rootReducer = combineReducers({
  form: formReducer,
  loader: loaderReducer,
  generalChecks: generalChecksReducer,
  student: studentReducer,
  user: userReducer,
  subjects: subjectsReducer,
  tutor: tutorReducer,
  common: commonReducer,
});

export default rootReducer;
