import {GET_CLASSES_SUCCEEDED, GET_CLASSES, SET_DASHBOARD_DATA} from '../actions/common';

const initialState = {
  classes: [],
  dashboard: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLASSES: {
      return {
        ...state,
        classesLoading: true,
      };
    }
    case GET_CLASSES_SUCCEEDED:
      return {
        ...state,
        classes: action.payload,
        classesLoading: false,
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload,
      }
    default:
      return state;
  }
}
