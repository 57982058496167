import React from 'react';
import axios from 'axios';
import { takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setCookie, destroyCookie } from 'nookies';
import moment from 'moment';
import Router from 'next/router';
import { stopSubmit } from 'redux-form';

import {
  USER_LOGIN,
  USER_REGISTER,
  FORGOT_PASSWORD,
  CONFIRM_ACCOUNT,
  RESET_PASSWORD_TOKEN_VALIDATION,
  RESET_PASSWORD,
  SOCIAL_LOGIN,
  USER_LOGOUT,
  RESET_ACCOUNT_PASSWORD,
  CHANGE_AVATAR,
  SAVE_USER_ROLE_AND_DOB,
  GET_TIMEZONE,
  setTimezoneAction,
} from '../actions/authentication';
import * as Authentication from '../api/authentication';
import { generateToken } from './application';
import { setUserAction } from '../actions/user';
import { STUDENT_ROUTES, HOMEPAGE_ROUTE, DEFAULT_ROLE_ROUTE } from '../routes';
import AlertMessage from '../../common/components/alert/alert';
import { errorOptions, USER_ROLES } from '../constants';
import { setAccountChecksAction, setSpinnerAction } from '../actions/general';

function* getUser() {
  const userDetails = yield Authentication.getUserDetails();
  if (userDetails.status !== 200) {
    toast(
      <AlertMessage severity="error" title="Error" message="ERROR" />,
      errorOptions
    );
  }
  return userDetails.data;
}
function* handleResponse(response) {
  setCookie(null, 'access_token', response.data.access_token, {
    maxAge: 90000000,
    sameSite: 'lax',
    path: '/',
  });
  setCookie(
    null,
    'expires_in',
    moment().add(response.data.expires_in, 'seconds').format('x'),
    {
      maxAge: 90000000,
      sameSite: 'lax',
      path: '/',
    }
  );
  setCookie(null, 'refresh_token', response.data.refresh_token, {
    maxAge: 90000000,
    sameSite: 'lax',
    path: '/',
  });

  axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
  axios.defaults.headers.common['Cache-Control'] = 'no-store, must-revalidate';
  axios.defaults.headers.common.Pragma = 'no-cache';
  axios.defaults.headers.Expires = 0;
  const userDetails = yield getUser();
  yield put(setUserAction({ details: userDetails.data, isLoggedIn: true }));
  if (userDetails.data.role === USER_ROLES.default || userDetails.data.role === null) {
    Router.push(DEFAULT_ROLE_ROUTE);
  } else if (!userDetails.data.profile.can_be_published) {
    Router.push(STUDENT_ROUTES.PRIVATE.DASHBOARD);
  } else {
    Router.push(STUDENT_ROUTES.PRIVATE.STUDENT_DASHBOARD);
  }
  //
  // yield put(showAlert({
  //     type: ALERT_TYPES.success,
  //     message: "Login success",
  // }));
}

function* login(action) {
  try {
    const response = yield Authentication.login(action.payload);
    yield call(handleResponse, response);
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('loginForm', err.response.data.errors));
    } else {
      let message;
      err.response.data.error === 'invalid_grant'
        ? (message = 'Invalid credentials')
        : (message = err.response.data.message);

      toast(
        <AlertMessage severity="error" title="Error" message={message} />,
        errorOptions
      );
    }
  }
}

function* register(action) {
  try {
    const response = yield Authentication.register(action.payload);
    yield call(handleResponse, response);
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('registerForm', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* forgotPassword(action) {
  try {
    yield Authentication.forgotPassword(action.payload);
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Successfully Sent"
      />,
      errorOptions
    );
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('forgotPassword', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}
function* confirmAccount(action) {
  try {
    yield Authentication.confirmAccount(action.payload);
    yield put(
      setAccountChecksAction({ whatToCheck: 'confirmAccount', value: true })
    );
    yield put(
      setSpinnerAction({ componentSpinner: 'checkAccount', value: false })
    );
  } catch (err) {
    yield put(
      setAccountChecksAction({ whatToCheck: 'confirmAccount', value: false })
    );
    yield put(
      setSpinnerAction({ componentSpinner: 'checkAccount', value: false })
    );
  }
}

function* resetPasswordTokenValidation(action) {
  try {
    const response = yield Authentication.resetPasswordTokenValidation(
      action.payload
    );

    yield put(
      setAccountChecksAction({
        whatToCheck: 'resetPasswordTokenValid',
        value: {
          isTokenValid: true,
          email: response.data.data.email,
          token: response.data.data.token,
        },
      })
    );
    yield put(
      setSpinnerAction({ componentSpinner: 'resetPassword', value: false })
    );
  } catch (err) {
    yield put(
      setAccountChecksAction({
        whatToCheck: 'resetPasswordTokenValid',
        value: {
          isTokenValid: false,
        },
      })
    );
    yield put(
      setSpinnerAction({ componentSpinner: 'resetPassword', value: false })
    );
  }
}

function* resetPassword(action) {
  try {
    yield Authentication.resetPassword(action.payload);
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Successfully changed password"
      />,
      errorOptions
    );
    Router.push(HOMEPAGE_ROUTE);
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* socialLogin(action) {
  try {
    const socialLoginResponse = yield Authentication.socialLogin(action);
    yield call(handleResponse, socialLoginResponse);
  } catch (err) {
    // console.log(err);
    setCookie(null, 'refresh_token', "", {
      maxAge: 90000000,
      sameSite: 'lax',
      path: '/',
    });
    yield destroyCookie(null, 'refresh_token');
    return yield call(generateToken, null);
  }
}

function* logout() {
  try {
    setCookie(null, 'access_token', "", {
      maxAge: 90000000,
      sameSite: 'lax',
      path: '/',
    });
    setCookie(null, 'refresh_token', "", {
      maxAge: 90000000,
      sameSite: 'lax',
      path: '/',
    });
    yield destroyCookie(null, 'access_token');
    yield destroyCookie(null, 'refresh_token');
    yield put(setUserAction({ details: [], isLoggedIn: false }));
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Successfully Logout"
      />,
      errorOptions
    );

    yield Authentication.logout();
  } catch (err) {
    // console.log(err);
  }
  yield call(generateToken, null);
  Router.push(HOMEPAGE_ROUTE);
}

function* resetAccountPassword(action) {
  try {
    yield Authentication.resetAccountPassword(action.payload);
    yield put(
      setAccountChecksAction({ whatToCheck: 'closeModal', value: true })
    );
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Successfully changed password"
      />,
      errorOptions
    );
  } catch (err) {
    if (err.response.status === 422) {
      yield put(
        stopSubmit('resetAccountPasswordForm', err.response.data.errors)
      );
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* changeAvatar(action) {
  try {
    yield Authentication.uploadAvatar(action.payload);
    const userDetails = yield getUser();
    yield put(setUserAction({ details: userDetails.data, isLoggedIn: true }));
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Successfully changed avatar"
      />,
      errorOptions
    );
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* saveRoleAndDob(action) {
  try {
    yield Authentication.saveRoleDobApi(action.payload);
    const userDetails = yield getUser();
    yield put(setUserAction({ details: userDetails.data, isLoggedIn: true }));
    Router.push(STUDENT_ROUTES.PRIVATE.DASHBOARD);
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* getTimezone(action) {
  try {
    const response = yield Authentication.getTimezone();
    yield put(setTimezoneAction(response.data));
  } catch (err) {
    // toast(
    //   <AlertMessage severity="error" title="Error" message={err.message} />,
    //   errorOptions
    // );
  }
}

export function* authenticationSaga() {
  yield takeLatest(USER_LOGIN, login);
  yield takeLatest(USER_REGISTER, register);
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(CONFIRM_ACCOUNT, confirmAccount);
  yield takeLatest(
    RESET_PASSWORD_TOKEN_VALIDATION,
    resetPasswordTokenValidation
  );
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeLatest(USER_LOGOUT, logout);
  yield takeLatest(RESET_ACCOUNT_PASSWORD, resetAccountPassword);
  yield takeLatest(CHANGE_AVATAR, changeAvatar);
  yield takeLatest(SAVE_USER_ROLE_AND_DOB, saveRoleAndDob);
  yield takeLatest(GET_TIMEZONE, getTimezone);
}
