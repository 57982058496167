import { takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';
import { stopSubmit } from 'redux-form';
import Router from 'next/router';

import * as Tutor from '../api/tutor';
import AlertMessage from '../../common/components/alert/alert';
import { errorOptions } from '../constants';
import {
  ADD_TUTOR_AVAILABILITY,
  EDIT_TUTOR,
  GET_AVAILABILITY_INTERVALS,
  GET_TUTOR_BY_SLUG,
  GET_TUTOR_SCHEDULE,
  ADD_CLASS,
  GET_AVAILABILITY_INTERVALS_BY_SLUG,
  SELECT_AVAILABILITY,
  EDIT_AVAILABILITY,
  GET_TUTOR_STUDENTS,
  GET_TUTOR_SUBJECTS,
  SELECT_CLASS,
  EDIT_CLASS,
  DELETE_CLASS,
  DELETE_AVAILABILITY,
  SEARCH_TUTORS_FROM_SEARCH_PAGE,
  SEARCH_TUTORS,
  PUBLISH_PROFILE_ACTION,
  AUTHORISE_STRIPE_ACCOUNT,
  setTutorAction,
  setTutorScheduleAction,
  setAvailabilityIntervalsAction,
  getAvailabilityIntervalsAction,
  getTutorScheduleAction,
  setSelectedAvailability,
  setTutorStudentsAction,
  setSelectedClassAction,
  setTutorSubjectsAction,
  setSearchTutors,
  SEARCH_SUBJECTS,
  setSearchSubjects,
  GET_TUTOR_REVIEWS,
  setTutorReviewsAction,
  GET_TUTOR,
  GET_TUTOR_REVIEWS_BY_SLUG,
  GET_STRIPE_CONNECT_URL,
  SET_FEE_INFO,
  setSurvey,
  GET_SURVEY_BY_SUBJECT,
  SUBMIT_SURVEY_FOR_VALIDATION,
  GET_TUTOR_DASHBOARD,
  setTutorDashboard,
  setNewClassAction,
  updateClassAction,
} from '../actions/tutor';
import { getUserAction } from '../actions/user';
import { setAccountChecksAction, setSpinnerAction } from '../actions/general';
import { TUTOR_ROUTES } from '../routes';

function* getTutorBySlug(action) {
  try {
    const response = yield Tutor.getTutorBySlug(action.payload);
    yield put(setTutorAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* getTutor(action) {
  try {
    const response = yield Tutor.getTutor();
    yield put(setTutorAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* editTutorProfile(action) {
  try {
    yield Tutor.editTutorProfile(action.payload);
    yield put(getUserAction());
    yield put(
      setAccountChecksAction({
        whatToCheck: 'profileMode',
        value: 'view',
      })
    );
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Profile edited successfully"
      />,
      errorOptions
    );
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('tutorProfileEditForm', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* getAvailabilityIntervals(action) {
  try {
    const response = yield Tutor.getAvailabilityIntervals(action.payload);
    yield put(setAvailabilityIntervalsAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* getTutorSchedule(action) {
  try {
    const schedule = yield Tutor.getTutorSchedule();
    yield put(setTutorScheduleAction(schedule.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* addClass(action) {
  try {
    const classResponse = yield Tutor.addClass(action.payload);
    yield put(getTutorScheduleAction());
    yield put(
      setAccountChecksAction({
        whatToCheck: 'closeModal',
        value: true,
      })
    );
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Class added successfully"
      />,
      errorOptions
    );
    if (classResponse?.data?.data) {
      yield put(setNewClassAction(classResponse?.data?.data));
    }
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('addClassForm', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* getAvailabilityIntervalsBySlug(action) {
  try {
    const response = yield Tutor.getAvailabilityIntervalsBySlug(action.payload);
    yield put(setAvailabilityIntervalsAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* addTutorAvailability(action) {
  try {
    yield Tutor.addTutorAvailability(action.payload);
    yield put(getAvailabilityIntervalsAction());
    yield put(
      setAccountChecksAction({
        whatToCheck: 'closeModal',
        value: true,
      })
    );
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Interval successfully added"
      />,
      errorOptions
    );
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('addAvailabilityForm', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* getTutorStudents() {
  try {
    const response = yield Tutor.getStudents();
    yield put(setTutorStudentsAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* getTutorSubjects() {
  try {
    const response = yield Tutor.getSubjects();
    yield put(setTutorSubjectsAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* selectClass(action) {
  try {
    const schedule = yield select(state => state.tutor.schedule);
    const selectedClass = schedule.filter(
      item => item.uuid === action.payload.id
    );

    yield put(setSelectedClassAction(selectedClass[0]));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* editClass(action) {
  try {
    const classResponse = yield Tutor.editClass(action.payload);
    yield put(getTutorScheduleAction());
    yield put(
      setAccountChecksAction({
        whatToCheck: 'closeModal',
        value: true,
      })
    );
    yield put(setSelectedClassAction(null));
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Class edited successfully"
      />,
      errorOptions
    );
    if (classResponse?.data?.data) {
      yield put(updateClassAction(classResponse?.data?.data));
    }
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('editClassForm', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* selectAvailability(action) {
  try {
    const availabilityIntervals = yield select(
      state => state.tutor.availability
    );
    const selectedAvailabilityInterval = availabilityIntervals.filter(
      item => item.uuid === action.payload.id
    );

    yield put(setSelectedAvailability(selectedAvailabilityInterval[0]));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* editTutorAvailability(action) {
  try {
    yield Tutor.editTutorAvailability(action.payload);
    yield put(getAvailabilityIntervalsAction());
    yield put(
      setAccountChecksAction({
        whatToCheck: 'closeModal',
        value: true,
      })
    );
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Interval successfully edited"
      />,
      errorOptions
    );
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('editAvailabilityForm', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* deleteClass(action) {
  try {
    yield Tutor.deleteClass(action.payload);
    yield put(getTutorScheduleAction());
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Class successfully deleted"
      />,
      errorOptions
    );
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* deleteAvailability(action) {
  try {
    yield Tutor.deleteAvailability(action.payload);
    yield put(getAvailabilityIntervalsAction());
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Interval successfully deleted"
      />,
      errorOptions
    );
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* searchTutors(action) {
  try {
    const response = yield Tutor.searchTutors(action.payload);
    yield put(setSearchTutors(response.data.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* searchSubjects(action) {
  try {
    const response = yield Tutor.searchSubjects(action.payload);
    yield put(setSearchSubjects(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* searchTutorsWithFilters(action) {
  try {
    const response = yield Tutor.searchTutors(action.payload);
    yield put(setSearchTutors(response.data.data));
    yield put(setSpinnerAction({ componentSpinner: 'tutorSearch', value: false }))
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
    yield put(setSpinnerAction({ componentSpinner: 'studentSearch', value: false }))
  }
}

function* publishProfile(action) {
  try {
    const response = yield Tutor.publishProfile(action.payload);
    yield put(getUserAction());
  } catch (err) {
    toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
  }
}

function* authoriseStripeAccount() {
  try {
    const response = yield Tutor.authoriseStripeTutorAccount();
    yield put(getUserAction());
    Router.push(TUTOR_ROUTES.PRIVATE.DASHBOARD);
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Your account was successfully connected with Stripe"
      />,
      errorOptions
    );
  } catch (err) {
    toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
  }
}

function* getTutorReviews(action) {
  try {
    const response = yield Tutor.getTutorReviews();
    yield put(setTutorReviewsAction(response.data.data));
  } catch (err) {
    toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
  }
}

function* getTutorReviewsBySlug(action) {
  try {
    const response = yield Tutor.getTutorReviewsBySlug(action.payload);
    yield put(setTutorReviewsAction(response.data.data));
  } catch (err) {
    toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
  }
}

function* getStripeConnectTutorUrl(action) {
  try {
    const response = yield Tutor.getTutorStripeConnectUrlApi();
    // Router.push(response.data.data);
    window.location.assign(response.data.data);
  } catch (err) {
    toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
  }
}

function* setFeeInfo(action) {
  try {
    yield Tutor.setFeeInfo(action.payload);
    yield put(getUserAction());
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* getSurveyBySubject(action) {
  try {
    const response = yield Tutor.getSurveyBySubject(action.payload);
    yield put(setSurvey(response.data.data));
  } catch (err) {
    if (err.response.status === 400) {
      toast(
        <AlertMessage severity="error" title="Error" message={err.response.data.message}/>,
        errorOptions
      );
    } else {
      toast(
        <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
      );
    }
  }
}

function* submitSurveyForValidation(action) {
  try {
    yield Tutor.submitSurveyForValidation(action.payload);
    toast(
        <AlertMessage severity="success" title="Success" message="Quiz passed!" />,
        errorOptions
    );
    yield put(getUserAction());
  } catch (err) {
    if (err.response.status === 400) {
      toast(
          <AlertMessage severity="error" title="Error" message={err.response.data.message}/>,
          errorOptions
      );
    } else {
      toast(
          <AlertMessage severity="error" title="Error" message={err.message}/>,
          errorOptions
      );
    }
  }
}

function* getTutorDashboard(action) {
  try {
    const response = yield Tutor.getTutorDashboard(action.payload);
    yield put(setTutorDashboard(response.data.data));
  } catch (err) {
    if (err.response.status === 400) {
      toast(
        <AlertMessage severity="error" title="Error" message={err.response.data.message}/>,
        errorOptions
      );
    } else {
      toast(
        <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
      );
    }
  }
}


export function* tutorSaga() {
  yield takeLatest(GET_TUTOR_BY_SLUG, getTutorBySlug);
  yield takeLatest(EDIT_TUTOR, editTutorProfile);
  yield takeLatest(ADD_TUTOR_AVAILABILITY, addTutorAvailability);
  yield takeLatest(GET_AVAILABILITY_INTERVALS, getAvailabilityIntervals);
  yield takeLatest(GET_TUTOR_SCHEDULE, getTutorSchedule);
  yield takeLatest(ADD_CLASS, addClass);
  yield takeLatest(
    GET_AVAILABILITY_INTERVALS_BY_SLUG,
    getAvailabilityIntervalsBySlug
  );
  yield takeLatest(SELECT_AVAILABILITY, selectAvailability);
  yield takeLatest(EDIT_AVAILABILITY, editTutorAvailability);
  yield takeLatest(GET_TUTOR_STUDENTS, getTutorStudents);
  yield takeLatest(GET_TUTOR_SUBJECTS, getTutorSubjects);
  yield takeLatest(SELECT_CLASS, selectClass);
  yield takeLatest(EDIT_CLASS, editClass);
  yield takeLatest(DELETE_CLASS, deleteClass);
  yield takeLatest(DELETE_AVAILABILITY, deleteAvailability);
  yield takeLatest(SEARCH_TUTORS, searchTutors);
  yield takeLatest(SEARCH_SUBJECTS, searchSubjects);
  yield takeLatest(SEARCH_TUTORS_FROM_SEARCH_PAGE, searchTutorsWithFilters);
  yield takeLatest(PUBLISH_PROFILE_ACTION, publishProfile);
  yield takeLatest(AUTHORISE_STRIPE_ACCOUNT, authoriseStripeAccount);
  yield takeLatest(GET_TUTOR_REVIEWS, getTutorReviews);
  yield takeLatest(GET_TUTOR_REVIEWS_BY_SLUG, getTutorReviewsBySlug);
  yield takeLatest(GET_TUTOR, getTutor);
  yield takeLatest(GET_STRIPE_CONNECT_URL, getStripeConnectTutorUrl);
  yield takeLatest(SET_FEE_INFO, setFeeInfo);
  yield takeLatest(GET_SURVEY_BY_SUBJECT, getSurveyBySubject);
  yield takeLatest(SUBMIT_SURVEY_FOR_VALIDATION, submitSurveyForValidation);
  yield takeLatest(GET_TUTOR_DASHBOARD, getTutorDashboard);
}
