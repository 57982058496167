import axios from 'axios';

import { buildPath } from './base';

export function* getStudentBySlug(data) {
  const url = buildPath(`/student/slug/${data}`);

  return yield axios.get(url);
}

export function* editStudentProfile(data) {
  const url = buildPath('/student');

  return yield axios.post(url, data);
}

export function* getStudentSchedule(data) {
  const url = buildPath('/student/class');

  return yield axios.get(url, data);
}

export function* hireTutor(tutorUuid) {
  const url = buildPath(`/student/${tutorUuid}/hire`);

  return yield axios.get(url);
}

export function* getTutors() {
  const url = buildPath('student/tutors');

  return yield axios.get(url);
}

export function* getTutorByUuid(tutorUuid) {
  const url = buildPath(`/student/tutors/${tutorUuid}`);

  return yield axios.get(url);
}

export function* getTutorPackages(tutorUuid) {
  const url = buildPath(`tutor/${tutorUuid}/packages`);

  return yield axios.get(url);
}

export function* getTutorPaymentHistory(tutorUuid) {
  const url = buildPath(`student/tutors/${tutorUuid}/payment-history`);

  return yield axios.get(url);
}

export function* addTutorReview(data) {
  const url = buildPath('student/review');

  return yield axios.post(url, data);
}

export function* updateTutorReview(data, reviewUuid) {
  const url = buildPath(`student/review/${reviewUuid}`);

  return yield axios.post(url, data);
}

export function* publishProfile() {
  const url = buildPath('/my-profile/publish');

  return yield axios.post(url);
}

export function* searchStudents(data) {
  const url = buildPath('/search-students', data);

  return yield axios.get(url);
}

export function* saveCreditCard(data) {
  const url = buildPath(`/student/save-credit-card/${data}`);

  return yield axios.post(url, data);
}

export function* retryFailedPayments() {
  const url = buildPath(`/student/retry-failed-payments`);

  return yield axios.get(url);
}

export function* getCancellationTime(classUuid) {
  const url = buildPath(`/student/class/${classUuid}/cancellation-time`);

  return yield axios.get(url);
}

export function* cancelClass(data) {
  const url = buildPath(`/student/class/${data.classUuid}/cancellation-time`);

  return yield axios.post(url, data);
}