import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector as useReduxSelector } from 'react-redux';
import { isToday } from 'date-fns';

import { buildPath } from '../../../../api/base';
import { selectors } from '../../../chat/state';
import { useSelector } from '../../../chat/state/context';
import { CONNECT_WITH_STRIPE_URL } from '../../../../api/api';

const classTrackInterval = process.env.NEXT_PUBLIC_CLASS_TRACKING_INTERVAL ?? 5;
const SCHEDULE_OFFSET = 1000 * (process.env.NEXT_PUBLIC_SCHEDULE_OFFSET || 15);

const sendData = data => {
  if (!data?.id) {
    return;
  }
  const url = buildPath(`/class/${data.id}/activity`);
  const promise = axios
    .post(url, data)
    .then(response => {
      return response;
    })
    .catch(e => {});
  return promise;
};

const useClassesSelector = state => {
  return state.common.classes;
};

export default function useClassTracker(
  room,
  localTracks,
  currentView,
  isSharing
) {
  const conversation = useSelector(selectors.currentConversation);
  const classes = useReduxSelector(useClassesSelector);
  const roomData = useRef(null);
  const conversationId = conversation?.id;
  const classesData =
    room.localParticipant &&
    classes.filter(
      cd =>
        room.localParticipant.identity === cd?.tutor_user_uuid &&
        (cd.uuid === conversation.id ||
          cd.parent_uuid === conversation.id ||
          (cd.participants === 1 &&
            cd.students[0]?.uuid_user === conversationId))
    );

  const classData =
    room.localParticipant &&
    classesData &&
    classesData.find(c => {
      const now = Date.now();
      const scheduledStartTime = new Date(c?.date + ' ' + c?.start_time);
      const scheduledEndTime = new Date(c?.date + ' ' + c?.end_time);

      return (
        now > scheduledStartTime.getTime() - SCHEDULE_OFFSET &&
        now < scheduledEndTime.getTime() + SCHEDULE_OFFSET
      );
    });
  const classId = classData?.uuid;

  useEffect(() => {
    roomData.current = {
      id: classId,
      roomId: room.sid,
      timestamp: Date.now(),
      dominantSpeaker: room.dominantSpeaker?.identity,
      currentView,
    };
  }, [conversation, conversationId, classId, room.state]);

  useEffect(() => {
    if (room.state !== 'connected') {
      return;
    }

    const intId = setInterval(() => {
      const participants = [
        room.localParticipant,
        ...room.participants.values(),
      ];
      roomData.current.participants = participants.map(p => {
        const isLocal = p.identity === room.localParticipant.identity;
        const tracks = isLocal ? localTracks : Array.from(p.tracks.values());
        return {
          id: p.identity,
          signalingRegion: p.signalingRegion || 'N/A',
          state: p.state,
          isSharingScreen: isLocal
            ? isSharing
            : tracks.some(t => (t.trackName || t.name).includes('screen')),
          isAudioEnabled: tracks.some(t => t.kind === 'audio'),
          isCameraEnabled: tracks.some(t =>
            (t.trackName || t.name).includes('camera')
          ),
          networkQualityLevel: p.networkQualityLevel,
        };
      });
      sendData(roomData.current);
    }, 1000 * classTrackInterval);
    return () => {
      intId && clearInterval(intId);
    };
  }, [conversation, conversationId, classId, room.state]);
}
