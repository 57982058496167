import React, { createContext, useContext, useReducer, useState } from 'react';

import { settingsReducer, initialSettings } from './settings/settingsReducer';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';

export const StateContext = createContext(null);

export default function AppStateProvider(props) {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [token, setToken] = useState('');

  const contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    token,
    ...usePasscodeAuth(),
  };

  const getToken = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setToken(res);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return err;
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, setToken }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
