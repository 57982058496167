import React, { createContext, useEffect, useState } from 'react';

import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';
import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler';
import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRoom from './useRoom/useRoom';
import useClassTracker from '../../hooks/useClassTracker/useClassTracker';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export const VideoContext = createContext(null);

export function VideoProvider({
  options,
  children,
  onError = () => {},
  onDisconnect = () => {},
}) {
  const [showWhiteboard, setShowWhiteboard] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const onErrorCallback = error => {
    onError(error);
  };

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    createLocalTracks,
    isAcquiringLocalTracks,
  } = useLocalTracks();

  const { room, isConnecting, connect, disconnect, connectionState } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );

  const [participants, setParticipants] = useState(
    Array.from(room.participants?.values() || [])
  );

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);
  useClassTracker(
    room,
    localTracks,
    showWhiteboard ? 'whiteboard' : 'video',
    isSharing
  );

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        connectionState,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        createLocalTracks,
        participants,
        setParticipants,
        connect,
        disconnect,
        isAcquiringLocalTracks,
        showWhiteboard,
        setShowWhiteboard,
        showChat,
        setShowChat,
        isSharing,
        setIsSharing,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/*
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
