import React from 'react';
import { takeLatest, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { stopSubmit } from 'redux-form';

import * as Student from '../api/student';
import * as Payment from '../api/payment';
import {
  GET_STUDENT_BY_SLUG,
  EDIT_STUDENT,
  GET_STUDENT_SCHEDULE,
  SELECT_CLASS_STUDENT,
  HIRE_TUTOR,
  GET_TUTORS,
  GET_TUTOR_PACKAGES,
  GET_TUTOR_PAYMENT_HISTORY,
  GET_TUTOR_BY_UUID,
  ADD_REVIEW,
  UPDATE_REVIEW,
  PUBLISH_STUDENT_PROFILE_ACTION,
  setStudentAction,
  setStudentScheduleAction,
  setSelectedClassAction,
  setTutors,
  setTutorPackagesAction,
  setTutorPaymentHistoryAction,
  setTutorByUuidAction,
  getTutorPaymentHistoryAction,
  getTutorByUuidAction,
  SEARCH_STUDENTS_FROM_SEARCH_PAGE,
  setSearchStudents,
  SAVE_CREDIT_CARD_ACTION,
  RETRY_FAILED_PAYMENTS,
  GET_CANCELLATION_TIME,
  setCancellationTimeAction,
  CANCEL_CLASS,
  getStudentScheduleAction,
} from '../actions/student';
import AlertMessage from '../../common/components/alert/alert';
import { errorOptions } from '../constants';
import {getUserAction, setUserProfileAction} from '../actions/user';
import { setAccountChecksAction, setSpinnerAction } from '../actions/general';
import { PAY_TUTOR, CONFIRM_PAYMENT } from '../actions/payment';
import { setTutorAction } from '../actions/tutor';

function* getStudentBySlug(action) {
  try {
    const response = yield Student.getStudentBySlug(action.payload);
    yield put(setStudentAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* editStudentProfile(action) {
  try {
    yield Student.editStudentProfile(action.payload);
    yield put(getUserAction());
    yield put(
      setAccountChecksAction({ whatToCheck: 'profileMode', value: 'view' })
    );
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Profile edited successfully"
      />,
      errorOptions
    );
  } catch (err) {
    if (err.response.status === 422) {
      yield put(stopSubmit('studentProfileEditForm', err.response.data.errors));
    } else {
      toast(
        <AlertMessage
          severity="error"
          title="Error"
          message={err.response.data.message}
        />,
        errorOptions
      );
    }
  }
}

function* getStudentSchedule() {
  try {
    const schedule = yield Student.getStudentSchedule();
    yield put(setStudentScheduleAction(schedule.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* selectClass(action) {
  try {
    const schedule = yield select(state => state.student.schedule);
    const selectedClass = schedule.filter(
      item => item.uuid === action.payload.id
    );

    yield put(setSelectedClassAction(selectedClass[0]));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* hireTutor(action) {
  try {
    const response = yield Student.hireTutor(action.payload);
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="Tutor Hired successfully"
      />,
      errorOptions
    );
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* getTutors() {
  try {
    const response = yield Student.getTutors();
    yield put(setTutors(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
    );
  }
}

function* getTutorPackages(action) {
  try {
    const response = yield Student.getTutorPackages(action.payload);
    yield put(setTutorPackagesAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
    );
  }
}

function* getTutorPaymentHistory(action) {
  try {
    const response = yield Student.getTutorPaymentHistory(action.payload);
    yield put(setTutorPaymentHistoryAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
    );
  }
}

function* payTutor(action) {
    try {
        const response = yield Payment.payTutorPackage(action.payload.tutorUuid, action.payload.packageUuid, action.payload.qty);
        if (response.status === 200) {
            yield put(setAccountChecksAction({ whatToCheck: 'openPaymentModal', value: true }));
            yield put(setAccountChecksAction({ whatToCheck: 'payment_client_secret', value: response.data.data.client_secret }));
        }
    } catch (err) {
        toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
    }
}

function* confirmTutorPayment(action) {
    try {
        const tutor = yield select(state => state.student.tutor.details);
        const response = yield Payment.confirmTutorPayment(action.payload);
        if (response.status === 200) {
            yield put(setAccountChecksAction({ whatToCheck: 'closePaymentModal', value: true }));
            yield put(setAccountChecksAction({ whatToCheck: 'payment_client_secret', value: null }));
            yield put(getTutorByUuidAction(tutor.uuid));
            yield put(getTutorPaymentHistoryAction(tutor.uuid));
            toast(<AlertMessage severity="success" title="Success" message="Payment Success" />, errorOptions);
        }
    } catch (err) {
        toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
    }
}

function* getTutorByUuid(action) {
  try {
    const response = yield Student.getTutorByUuid(action.payload);
    yield put(setTutorByUuidAction(response.data.data));
    yield put(setTutorAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
    );
  }
}

function* addReview(action) {
  try {
    const response = yield Student.addTutorReview(action.payload);
    if (response.status === 200) {
      yield put(getTutorByUuidAction(response.data.data.tutor_uuid));
    }
} catch (err) {
    if (err.response.status === 422) {
      const msg = Object.keys(err.response.data.errors).map((errorKey) => {
        return <p>{err.response.data.errors[errorKey][0]}</p>;
      });

      toast(
        <AlertMessage severity="error" title="Error" message={msg} />,
        errorOptions
      );
    } else {
      toast(
        <AlertMessage severity="error" title="Error" message={err.message} />,
        errorOptions
      );
    }
  }
}

function* updateReview(action) {
  try {
    const response = yield Student.updateTutorReview(action.payload.data, action.payload.reviewUuid);
    if (response.status === 200) {
      yield put(getTutorByUuidAction(response.data.data.tutor_uuid));
    }
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* publishProfile(action) {
  try {
    const response = yield Student.publishProfile(action.payload);
    yield put(getUserAction());
  } catch (err) {
    toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
  }
}

function* searchStudentsWithFilters(action) {
  try {
    const response = yield Student.searchStudents(action.payload);
    yield put(setSearchStudents(response.data.data));
    yield put(setSpinnerAction({ componentSpinner: 'studentSearch', value: false }));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
    yield put(setSpinnerAction({ componentSpinner: 'studentSearch', value: false }));
  }
}

function* saveCreditCard(action) {
  try {
    const response = yield Student.saveCreditCard(action.payload);
    yield put(setUserProfileAction(response.data.data));
    toast(<AlertMessage severity="success" title="Success" message="Credit card has been added" />, errorOptions);
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.response.data.message} />,
      errorOptions
    );
    // yield put(setSpinnerAction({ componentSpinner: 'studentSearch', value: false }));
  }
}

function* retryFailedPayments(action) {
  try {
    yield put(
      setSpinnerAction({ componentSpinner: 'retryPayments', value: true })
    );
    const response = yield Student.retryFailedPayments();
    yield put(getUserAction());
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
  yield put(
    setSpinnerAction({ componentSpinner: 'retryPayments', value: false })
  );
}

function* getCancellationTime(action) {
  try {
    const response = yield Student.getCancellationTime(action.payload);
    yield put(setCancellationTimeAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}


function* cancelClass(action) {
  try {
    yield Student.cancelClass(action.payload);
    yield put(getStudentScheduleAction());
    toast(<AlertMessage severity="success" title="Success" message="Class has been canceled" />, errorOptions);
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

export function* studentSaga() {
  yield takeLatest(GET_STUDENT_BY_SLUG, getStudentBySlug);
  yield takeLatest(EDIT_STUDENT, editStudentProfile);
  yield takeLatest(GET_STUDENT_SCHEDULE, getStudentSchedule);
  yield takeLatest(SELECT_CLASS_STUDENT, selectClass);
  yield takeLatest(HIRE_TUTOR, hireTutor);
  yield takeLatest(GET_TUTORS, getTutors);
  yield takeLatest(PAY_TUTOR, payTutor);
  yield takeLatest(CONFIRM_PAYMENT, confirmTutorPayment);
  yield takeLatest(GET_TUTOR_PACKAGES, getTutorPackages);
  yield takeLatest(GET_TUTOR_PAYMENT_HISTORY, getTutorPaymentHistory);
  yield takeLatest(GET_TUTOR_BY_UUID, getTutorByUuid);
  yield takeLatest(ADD_REVIEW, addReview);
  yield takeLatest(UPDATE_REVIEW, updateReview);
  yield takeLatest(PUBLISH_STUDENT_PROFILE_ACTION, publishProfile);
  yield takeLatest(SEARCH_STUDENTS_FROM_SEARCH_PAGE, searchStudentsWithFilters);
  yield takeLatest(SAVE_CREDIT_CARD_ACTION, saveCreditCard);
  yield takeLatest(RETRY_FAILED_PAYMENTS, retryFailedPayments);
  yield takeLatest(GET_CANCELLATION_TIME, getCancellationTime);
  yield takeLatest(CANCEL_CLASS, cancelClass);
}
