import { SET_ACCOUNT_CHECKS } from '../actions/general';

const initialState = {
  confirmAccount: false,
  resetPasswordTokenValid: {
    isTokenValid: false,
  },
  closeModal: false,
  profileMode: 'view',
  closePaymentModal: true,
  openPaymentModal: false,
  payment_client_secret: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_CHECKS:
      return {
        ...state,
        [`${action.payload.whatToCheck}`]: action.payload.value,
      };
    default:
      return state;
  }
};
