import {
  CLEAR_TUTOR_BY_UUID,
  CLEAR_TUTOR_PACKAGES,
  CLEAR_TUTOR_PAYMENT_HISTORY, SET_CANCELLATION_TIME,
  SET_SEARCH_STUDENT_FILTERS,
  SET_SEARCH_STUDENTS,
  SET_SELECTED_CLASS_STUDENT,
  SET_STUDENT,
  SET_STUDENT_SCHEDULE,
  SET_TUTOR_BY_UUID,
  SET_TUTOR_PACKAGES,
  SET_TUTOR_PAYMENT_HISTORY,
  SET_TUTORS,
} from '../actions/student';
import {SET_SEARCH_SUBJECTS} from "../actions/tutor";

const initialState = {
  details: [],
  schedule: [],
  selectedClass: null,
  tutors: [],
  tutor: {
    details: null,
    packages: [],
    paymentHistory: [],
  },
  search: [],
  searchSubjects: [],
  searchFilters: {
    search: null,
  },
  cancellationTime: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_STUDENT:
      return {
        ...state,
        details: action.payload,
      };
    case SET_STUDENT_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      };
    case SET_SELECTED_CLASS_STUDENT:
      return {
        ...state,
        selectedClass: action.payload,
      };
    case SET_TUTORS:
      return {
        ...state,
        tutors: action.payload,
      };
    case SET_TUTOR_PACKAGES:
      return {
        ...state,
        tutor: {
          ...state.tutor,
          packages: action.payload,
        },
      };
    case SET_TUTOR_PAYMENT_HISTORY:
      return {
        ...state,
        tutor: {
          ...state.tutor,
          paymentHistory: action.payload,
        },
      };
    case SET_TUTOR_BY_UUID:
      return {
        ...state,
        tutor: {
          ...state.tutor,
          details: action.payload,
        },
      };
    case CLEAR_TUTOR_PAYMENT_HISTORY:
    case CLEAR_TUTOR_BY_UUID:
    case CLEAR_TUTOR_PACKAGES:
      return {
        ...state,
        tutor: initialState.tutor,
      };
    case SET_SEARCH_STUDENTS:
      if (!state.search.data || action.payload.current_page === 1) {
        return {
          ...state,
          search: action.payload,
        };
      } else {
        action.payload.data = state.search.data.concat(action.payload.data);
        return {
          ...state,
          search: action.payload
        }
      }
    case SET_SEARCH_SUBJECTS:
      return {
        ...state,
        searchSubjects: action.payload,
      };
    case SET_SEARCH_STUDENT_FILTERS:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          [`${action.payload.key}`]: action.payload.value,
        },
      };
    case SET_CANCELLATION_TIME:
        return {
          ...state,
          cancellationTime: action.payload
        }
    default:
      return state;
  }
};
