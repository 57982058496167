import axios from 'axios';

import { buildOauthPath, buildPath } from './base';

export function* getTutor() {
  const url = buildPath('/tutor');

  return yield axios.get(url);
}

export function* getTutorBySlug(data) {
  const url = buildPath(`/tutor/slug/${data}`);

  return yield axios.get(url);
}

export function* editTutorProfile(data) {
  const url = buildPath('/tutor');

  return yield axios.post(url, data);
}

export function* getAvailabilityIntervals(data) {
  const url = buildPath('/tutor/interval');

  return yield axios.get(url, data);
}

export function* getTutorSchedule(data) {
  const url = buildPath('/tutor/class');

  return yield axios.get(url, data);
}

export function* addClass(data) {
  const url = buildPath('/tutor/class');

  return yield axios.post(url, data);
}

export function* getAvailabilityIntervalsBySlug(data) {
  const url = buildPath(`/tutor/slug/${data}/availability`);

  return yield axios.get(url, data);
}

export function* addTutorAvailability(data) {
  const url = buildPath('/tutor/interval');

  return yield axios.post(url, data);
}

export function* editTutorAvailability(data) {
  const url = buildPath(`/tutor/interval/${data.uuid}`);

  return yield axios.post(url, data);
}

export function* getTutorStudents(data) {
  const url = buildPath('/tutor/students');

  return yield axios.get(url, data);
}

export function* getStudents() {
  const url = buildPath('/tutor/students');

  return yield axios.get(url);
}

export function* getSubjects() {
  const url = buildPath('/tutor/subjects');

  return yield axios.get(url);
}

export function* editClass(data) {
  const url = buildPath(`/tutor/class/${data.uuid}`);

  return yield axios.post(url, data);
}

export function* deleteAvailability(data) {
  let url = buildPath(`/tutor/interval/${data.uuid}`);
  if (data.delete_future_classes === 'true') {
    url += '/true';
  }
  return yield axios.delete(url);
}

export function* deleteClass(data) {
  let url = buildPath(`/tutor/class/${data.uuid}`);
  if (data.delete_future_classes === 'true') {
    url += '/true';
  }
  return yield axios.delete(url);
}

export function* searchTutors(data) {
  const url = buildPath('/search', data);

  return yield axios.get(url);
}

export function* searchSubjects(data) {
  const url = buildPath('/search-subjects', data);

  return yield axios.get(url);
}

export function* publishProfile() {
    const url = buildPath('/my-profile/publish');

    return yield axios.post(url);
}

export function* authoriseStripeTutorAccount() {
    const url = buildPath('/tutor/confirm-stripe-is-connected');

    return yield axios.get(url);
}

export function* getTutorReviews() {
    const url = buildPath('/tutor/reviews');

    return yield axios.get(url);
}

export function* getTutorReviewsBySlug(data) {
    const url = buildPath(`/tutor/slug/${data}/reviews`);

    return yield axios.get(url);
}

export function* getTutorStripeConnectUrlApi() {
  const url = buildPath('/tutor/stripe-onboarding-url');

  return yield axios.get(url);
}

export function* setFeeInfo(data) {
  const url = buildPath('/tutor/confirmation-terms-and-conditions');

  return yield axios.post(url, data);
}

export function* getSurveyBySubject(subjectUuid) {
  const url = buildPath(`/tutor/subject-survey/${subjectUuid}`);

  return yield axios.get(url);
}

export function* submitSurveyForValidation(data) {
  const url = buildPath(`/tutor/subject-survey/${data.uuid}`);

  return yield axios.post(url, {data: data.answers});
}

export function* getTutorDashboard(data) {
  const url = buildPath(`/tutor/dashboard`);

  return yield axios.get(url);
}