export const GET_CLASSES = 'GET_CLASSES';
export const GET_CLASSES_SUCCEEDED = 'GET_CLASSES_SUCCEEDED';
export const SET_CONTACT = 'SET_CONTACT';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';

export const getClassesAction = (payload) => ({
  type: GET_CLASSES,
  payload,
});

export const setClassesAction = payload => ({
  type: GET_CLASSES_SUCCEEDED,
  payload,
});

export const setContactAction = payload => ({
  type: SET_CONTACT,
  payload,
});

export const getDashboardDataAction = () => ({
  type: GET_DASHBOARD_DATA,
});

export const setDashboardDataAction = (payload) => ({
  type: SET_DASHBOARD_DATA,
  payload,
});


