import { takeLatest, put, call } from 'redux-saga/effects';
import { parseCookies } from 'nookies';

import * as Authentication from '../api/authentication';
import { GET_USER_DETAILS, setUserAction } from '../actions/user';
import { generateToken } from './application';

function* getUserDetails() {
  try {
    const response = yield Authentication.getUserDetails();
    yield put(setUserAction({ details: response.data.data, isLoggedIn: true }));
  } catch (err) {
    const cookies = parseCookies();
    const refreshToken = cookies.refresh_token;
    return yield call(generateToken, refreshToken);
  }
}

export function* userSaga() {
  yield takeLatest(GET_USER_DETAILS, getUserDetails);
}
