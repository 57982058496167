import React from 'react';
import { put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {reset} from 'redux-form';
import * as Common from '../api/common';
import AlertMessage from '../../common/components/alert/alert';
import { errorOptions } from '../constants';
import {
  setClassesAction,
  setDashboardDataAction,
  GET_CLASSES,
  SET_CONTACT,
  GET_DASHBOARD_DATA
} from '../actions/common';
import moment from 'moment';

function* getClasses(action) {
  try {
    let payload = action.payload ? action.payload : {};
    if ( Object.keys(payload).length === 0) {
      payload = {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }
    }
    const response = yield Common.getClasses(payload);
    yield put(setClassesAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* setContact(action) {
  try {
    yield Common.setContact(action.payload);
    yield put(reset("contactForm"))
    toast(
      <AlertMessage
        severity="success"
        title="Success"
        message="The message has been send"
      />,
      errorOptions
    );
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

function* getDashboardData(action) {
  try {
    const response = yield Common.getDashboardData();
    yield put(setDashboardDataAction(response.data.data));
  } catch (err) {
    toast(
      <AlertMessage severity="error" title="Error" message={err.message} />,
      errorOptions
    );
  }
}

export function* commonSaga() {
  yield takeLatest(GET_CLASSES, getClasses);
  yield takeLatest(SET_CONTACT, setContact);
  yield takeLatest(GET_DASHBOARD_DATA, getDashboardData);
}
