import { SET_SUBJECTS } from '../actions/subjects';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SUBJECTS:
      return action.payload;
    default:
      return state;
  }
};
