import {
  SET_AVAILABILITY_INTERVALS,
  SET_TUTOR,
  SET_TUTOR_SCHEDULE,
  SET_SELECTED_CLASS,
  SET_TUTOR_STUDENTS,
  SET_TUTOR_SUBJECTS,
  SET_SELECTED_AVAILABILITY,
  SET_SEARCH_TUTORS,
  SET_SEARCH_TUTOR_FILTERS,
  SET_SEARCH_SUBJECTS,
  SET_TUTOR_REVIEWS,
  SET_STRIPE_CONNECT_URL,
  SET_SURVEY,
  SET_TUTOR_DASHBOARD,
  SET_NEW_CLASS,
  UPDATE_CLASS,
} from '../actions/tutor';

const initialState = {
  details: null,
  schedule: [],
  availability: [],
  selectedAvailability: null,
  selectedClass: null,
  newClass: null,
  updatedClass: null,
  students: [],
  subjects: [],
  search: [],
  searchSubjects: [],
  searchFilters: {
    search: null,
  },
  reviews: [],
  stripeConnectUrl: null,
  survey: null,
  dashboard: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TUTOR:
      return {
        ...state,
        details: action.payload,
      };
    case SET_AVAILABILITY_INTERVALS:
      return {
        ...state,
        availability: action.payload,
      };
    case SET_TUTOR_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      };
    case SET_SELECTED_AVAILABILITY:
      return {
        ...state,
        selectedAvailability: action.payload,
      };
    case SET_SELECTED_CLASS:
      return {
        ...state,
        selectedClass: action.payload,
      };
    case SET_TUTOR_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
      };
    case SET_TUTOR_STUDENTS:
      return {
        ...state,
        students: action.payload,
      };
    case SET_SEARCH_TUTORS:
      if (!state.search.data || action.payload.current_page === 1) {
        return {
          ...state,
          search: action.payload,
        };
      } else {
        action.payload.data = state.search.data.concat(action.payload.data);
        return {
          ...state,
          search: action.payload
        }
      }
    case SET_SEARCH_SUBJECTS:
      return {
        ...state,
        searchSubjects: action.payload,
      };
    case SET_SEARCH_TUTOR_FILTERS:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          [`${action.payload.key}`]: action.payload.value,
        },
      };
    case SET_TUTOR_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case SET_STRIPE_CONNECT_URL:
      return {
        ...state,
        stripeConnectUrl: action.payload,
      };
    case SET_SURVEY:
      return {
        ...state,
        survey: action.payload,
      }
    case SET_TUTOR_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      }
    case SET_NEW_CLASS:
      return {
        ...state,
        newClass: action.payload,
      };
    case UPDATE_CLASS:
      return {
        ...state,
        updatedClass: action.payload,
      };
    default:
      return state;
  }
};
