import {GET_USER_DETAILS_SUCCEDDED, SET_USER_PROFILE} from '../actions/user';
import { SET_TIMEZONE } from '../actions/authentication';

const initialState = {
  details: [],
  isLoggedIn: false,
  timezone: '-',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_DETAILS_SUCCEDDED:
      return {
        ...state,
        details: action.payload.details,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case SET_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      };
    case SET_USER_PROFILE:
      const userDetails = state.details;
      userDetails.profile = action.payload;
      return {
        ...state,
        details: userDetails,
      }
    default:
      return state;
  }
}
