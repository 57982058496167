import { SET_LOADER } from '../actions/loader';

const initialState = {
  generalLoader: false,
  checkAccount: true,
  resetPassword: true,
  tutorSearch: false,
  studentSearch: false,
  retryPayments: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        [`${action.payload.componentSpinner}`]: action.payload.value,
      };
    default:
      return state;
  }
};
