import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';

import * as Subjects from '../api/subjects';
import AlertMessage from '../../common/components/alert/alert';
import { errorOptions } from '../constants';
import {
  GET_SUBJECTS,
  setSubjectsAction,
} from '../actions/subjects';

function* getSubjects() {
  try {
    const response = yield Subjects.getSubjectsList();
    yield put(setSubjectsAction(response.data.data));
  } catch (err) {
    toast(<AlertMessage severity="error" title="Error" message={err.message} />, errorOptions);
  }
}

export function* subjectsSaga() {
  yield takeLatest(GET_SUBJECTS, getSubjects);
}
