import { all } from 'redux-saga/effects';

import { applicationSaga } from './application';
import { authenticationSaga } from './authentication';
import { studentSaga } from './student';
import { userSaga } from './user';
import { subjectsSaga } from './subjects';
import { tutorSaga } from './tutor';
import { commonSaga } from './common';

export default function* rootSaga() {
  yield all([
    applicationSaga(),
    authenticationSaga(),
    studentSaga(),
    userSaga(),
    subjectsSaga(),
    tutorSaga(),
    commonSaga(),
  ]);
}
