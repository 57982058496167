import { createMuiTheme } from '@material-ui/core';

import { typographyStyleGuide } from './styleGuide';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Roboto'],
    ...typographyStyleGuide,
  },
  palette: {
    background: {
      paper: '#F0F3F8',
      default: '#FFF',
    },
    text: {
      primary: '#1F3B63',
      secondary: '#728196',
      disabled: '#C0CBD7',
      hint: '#A1EAE9',
    },
    divider: '#E3E7EE',
    secondary: {
      main: '#FF0088',
      light: '#A1EAE9',
      dark: '#728196',
      contrastText: '#fff',
    },
    primary: {
      main: '#70CDCD',
      light: '#00E1E3',
      dark: '#1F3B63',
      contrastText: '#fff',
    },
  },
});
