import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/root';
import { getAccessTokenAction, GET_ACCESS_TOKEN } from './actions/application';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();

const tokenMiddleware = store => next => action => {
  // const cookies = document.cookie;
  //
  // let accessToken = cookies.accessToken;
  //
  // if(!accessToken) {
  console.log(action.type);
  //     if (action.type !== GET_ACCESS_TOKEN) {
  //         store.dispatch(getAccessTokenAction);
  //     }
  // }

  next(action);
};

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;
