import EventEmitter from 'events';
import Video from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';

import { isMobile } from '../../../utils';

// window.TwilioVideo = Video;

export default function useRoom(localTracks, onError, options) {
  const [room, setRoom] = useState(new EventEmitter());
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionState, setConnectionState] = useState('disconnected');
  const localTracksRef = useRef([]);

  const disconnect = (unpublishTracks = true) => {
    const { localParticipant } = room;

    // Unpublish all tracks when call is ended
    if (unpublishTracks) {
      localParticipant.tracks.forEach(publication => {
        const { track } = publication;
        const localTrackPublication = localParticipant?.unpublishTrack(track);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        track.stop();
      });
    }

    setConnectionState('disconnecting');
    room && room.disconnect();
  };

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const connect = useCallback(
    (token, name) => {
      const connectOptions = {
        ...options,
        tracks: [],
        video: { height: 720, frameRate: 24, width: 1280 },
      };
      if (name) {
        connectOptions.name = name;
      }

      setIsConnecting(true);
      setConnectionState('connecting');
      return Video.connect(token, connectOptions).then(
        newRoom => {
          setRoom(newRoom);
          setConnectionState('connected');
          const disconnect = () => newRoom.disconnect();

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => {
              setRoom(new EventEmitter());
              setConnectionState('disconnected');
            }, 500);
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          window.twilioRoom = newRoom;

          localTracksRef.current.forEach(track =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track, {
              priority: track.kind === 'video' ? 'low' : 'standard',
            })
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
          return newRoom;
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [options, onError]
  );

  return { room, isConnecting, connect, disconnect, connectionState };
}
