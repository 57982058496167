import {
  PUBLISH_PROFILE_ACTION,
  SEARCH_TUTORS_FROM_SEARCH_PAGE,
  SET_SEARCH_TUTOR_FILTERS,
  SET_SEARCH_TUTORS,
} from './tutor';

export const GET_STUDENT_BY_SLUG = 'GET_STUDENT_BY_SLUG';
export const SET_STUDENT = 'SET_STUDENT';
export const EDIT_STUDENT = 'EDIT_STUDENT';
export const GET_STUDENT_SCHEDULE = 'GET_STUDENT_SCHEDULE';
export const SET_STUDENT_SCHEDULE = 'SET_STUDENT_SCHEDULE';
export const SELECT_CLASS_STUDENT = 'SELECT_CLASS_STUDENT';
export const SET_SELECTED_CLASS_STUDENT = 'SET_SELECTED_CLASS_STUDENT';
export const HIRE_TUTOR = 'HIRE_TUTOR';
export const GET_TUTORS = 'GET_TUTORS';
export const SET_TUTORS = 'SET_TUTORS';
export const GET_TUTOR_PACKAGES = 'GET_TUTOR_PACKAGES';
export const SET_TUTOR_PACKAGES = 'SET_TUTOR_PACKAGES';
export const CLEAR_TUTOR_PACKAGES = 'CLEAR_TUTOR_PACKAGES';
export const GET_TUTOR_PAYMENT_HISTORY = 'GET_TUTOR_PAYMENT_HISTORY';
export const SET_TUTOR_PAYMENT_HISTORY = 'SET_TUTOR_PAYMENT_HISTORY';
export const CLEAR_TUTOR_PAYMENT_HISTORY = 'CLEAR_TUTOR_PAYMENT_HISTORY';
export const GET_TUTOR_BY_UUID = 'GET_TUTOR_BY_UUID';
export const SET_TUTOR_BY_UUID = 'SET_TUTOR_BY_UUID';
export const CLEAR_TUTOR_BY_UUID = 'CLEAR_TUTOR_BY_UUID';
export const ADD_REVIEW = 'ADD_REVIEW';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const PUBLISH_STUDENT_PROFILE_ACTION = 'PUBLISH_STUDENT_PROFILE_ACTION';
export const SEARCH_STUDENTS_FROM_SEARCH_PAGE = 'SEARCH_STUDENTS_FROM_SEARCH_PAGE';
export const SET_SEARCH_STUDENTS = 'SET_SEARCH_STUDENTS';
export const SET_SEARCH_STUDENT_FILTERS = 'SET_SEARCH_STUDENT_FILTERS';
export const SAVE_CREDIT_CARD_ACTION = 'SAVE_CREDIT_CARD_ACTION';
export const RETRY_FAILED_PAYMENTS = 'RETRY_FAILED_PAYMENTS';
export const GET_CANCELLATION_TIME = 'GET_CANCELLATION_TIME';
export const SET_CANCELLATION_TIME = 'SET_CANCELLATION_TIME';
export const CANCEL_CLASS = 'CANCEL_CLASS';

export const getStudentBySlugAction = (payload) => ({
  type: GET_STUDENT_BY_SLUG,
  payload,
});

export const setStudentAction = (payload) => ({
  type: SET_STUDENT,
  payload,
});

export const editStudentProfileAction = (payload) => ({
  type: EDIT_STUDENT,
  payload,
});

export const getStudentScheduleAction = (payload) => ({
  type: GET_STUDENT_SCHEDULE,
  payload,
});

export const setStudentScheduleAction = (payload) => ({
  type: SET_STUDENT_SCHEDULE,
  payload,
});

export const selectClassAction = (payload) => ({
  type: SELECT_CLASS_STUDENT,
  payload,
});

export const setSelectedClassAction = (payload) => ({
  type: SET_SELECTED_CLASS_STUDENT,
  payload,
});

export const hireTutorAction = (payload) => ({
  type: HIRE_TUTOR,
  payload,
});

export const getTutors = (payload) => ({
  type: GET_TUTORS,
  payload,
});

export const setTutors = (payload) => ({
  type: SET_TUTORS,
  payload,
});

export const getTutorPackagesAction = (payload) => ({
  type: GET_TUTOR_PACKAGES,
  payload,
});

export const setTutorPackagesAction = (payload) => ({
  type: SET_TUTOR_PACKAGES,
  payload,
});

export const clearTutorPackagesAction = () => ({
  type: CLEAR_TUTOR_PACKAGES,
});

export const getTutorPaymentHistoryAction = (payload) => ({
  type: GET_TUTOR_PAYMENT_HISTORY,
  payload,
});

export const setTutorPaymentHistoryAction = (payload) => ({
  type: SET_TUTOR_PAYMENT_HISTORY,
  payload,
});

export const clearTutorPaymentHistoryAction = () => ({
  type: CLEAR_TUTOR_PAYMENT_HISTORY,
});

export const getTutorByUuidAction = (payload) => ({
  type: GET_TUTOR_BY_UUID,
  payload,
});

export const setTutorByUuidAction = (payload) => ({
  type: SET_TUTOR_BY_UUID,
  payload,
});

export const clearTutorByUuidAction = () => ({
  type: CLEAR_TUTOR_BY_UUID,
});

export const addReviewAction = (payload) => ({
  type: ADD_REVIEW,
  payload,
});

export const updateReviewAction = (payload) => ({
  type: UPDATE_REVIEW,
  payload,
});

export const publishProfileAction = () => ({
  type: PUBLISH_STUDENT_PROFILE_ACTION,
});

export const searchStudentsFromSearchPage = (payload) => ({
  type: SEARCH_STUDENTS_FROM_SEARCH_PAGE,
  payload,
});

export const setSearchStudents = (payload) => ({
  type: SET_SEARCH_STUDENTS,
  payload,
});

export const setSearchStudentsFiltersAction = (payload) => ({
  type: SET_SEARCH_STUDENT_FILTERS,
  payload,
});

export const saveCreditCardAction = (payload) => ({
  type: SAVE_CREDIT_CARD_ACTION,
  payload,
});

export const retryFailedPaymentsActions = () => ({
  type: RETRY_FAILED_PAYMENTS,
});

export const getCancellationTimeAction = (payload) => ({
  type: GET_CANCELLATION_TIME,
  payload
});

export const setCancellationTimeAction = (payload) => ({
  type: SET_CANCELLATION_TIME,
  payload
})

export const cancelClassAction = (payload) => ({
  type: CANCEL_CLASS,
  payload
})
