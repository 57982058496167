export const PAY_TUTOR = 'PAY_TUTOR';
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';

export const payTutorAction = (payload) => ({
  type: PAY_TUTOR,
  payload,
});

export const confirmTutorPaymentAction = (payload) => ({
  type: CONFIRM_PAYMENT,
  payload,
});
