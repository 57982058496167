import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

export function fetchToken(identity, room = 'main') {
  return axios.post('/twilio-token', { identity, room });
  // return axios.post(`http://localhost:3000/token`, { id: identity });
}

export function getErrorMessage(message) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback((name, room) => {
    return fetchToken(name, room).then(res => res.data.token);
  });

  useEffect(() => {
    setIsAuthReady(true);
  }, []);

  return { isAuthReady, getToken };
}
