import axios from 'axios';

import { buildPath } from './base';

export function* payTutorPackage(tutorUuid, packageUuid, qty) {
    const url = buildPath(`/payment/pay/${tutorUuid}/${packageUuid}`);

    return yield axios.post(url, { quantity: qty });
}

export function* confirmTutorPayment(paymentData) {
    const url = buildPath('/payment/confirmation');

    return yield axios.post(url, paymentData);
}
