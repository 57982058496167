import axios from 'axios';

import { buildPath } from './base';

export function* getClasses(data) {
  const url = buildPath('/me/classes');

  return yield axios.get(url, { params: data });
}

export function* setContact(data) {
  const url = buildPath('/contact');

  return yield axios.post(url, data);
}

export function* getDashboardData() {
  const url = buildPath('/me/dashboard');

  return yield axios.get(url);
}
